import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import axios from '@/api/axios';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

export function getReportData(link, object) {
  return axios.post(link, object).then((response) => {
    if (response.data.success) { return response; }

    Vue.$toast.open({
      message: response.data.message,
      type: 'error',
    });
    response.data = null;

    return response;
  });
}
