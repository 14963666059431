var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('scrollable-container',[_c('placeholder',{directives:[{name:"show",rawName:"v-show",value:(_vm.state === _vm.reportEnum.requestAwaiting),expression:"state === reportEnum.requestAwaiting"}],attrs:{"text":_vm.text},on:{"buttonClick":_vm.buildReport}}),_c('loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.state === _vm.reportEnum.tableLoading),expression:"state === reportEnum.tableLoading"}]}),_c('container',{directives:[{name:"show",rawName:"v-show",value:(_vm.state === _vm.reportEnum.tableReady),expression:"state === reportEnum.tableReady"}],class:[
    ( _obj = {}, _obj[_vm.$style.message] = _vm.state === _vm.reportEnum.requestAwaiting, _obj ),
    _vm.$style.scrollableContent ],attrs:{"id":"printable"}},[(_vm.tableData.length === 0)?_c('div',{class:_vm.$style.emptyResult},[_vm._v(" Нет данных за указанный период ")]):_vm._e(),_vm._l((_vm.tableData),function(user,index){
  var _obj;
return _c('div',{key:user.id,class:_vm.$style.user},[_c('div',{class:_vm.$style.userStickyToggle,on:{"click":function($event){return _vm.toggle(user)}}},[_c('div',{class:[
          _vm.$style.expandButton,
          ( _obj = {}, _obj[_vm.$style.expanded] = user.isExpanded, _obj ) ]}),_c('div',{class:_vm.$style.name},[_vm._v(" "+_vm._s(user.name)+" ("+_vm._s(_vm.convertSecondsToDays(user.duration))+") ")])]),_vm._l((Object.entries(user.dates)),function(ref){
        var _obj;

        var key = ref[0];
        var value = ref[1];return _c('div',{directives:[{name:"show",rawName:"v-show",value:(user.isExpanded),expression:"user.isExpanded"}],key:key + user.id,class:_vm.$style.userData},[_c('div',{class:_vm.$style.toggle,on:{"click":function($event){return _vm.toggleDate(index, key)}}},[_c('div',{class:[
            _vm.$style.expandButton,
            ( _obj = {}, _obj[_vm.$style.expanded] = value.isExpanded, _obj ) ]}),_c('div',{class:_vm.$style.date},[_vm._v(" "+_vm._s(key)+" ("+_vm._s(_vm.convertSecondsToDays(value.duration))+") ")])]),_c('table',{directives:[{name:"show",rawName:"v-show",value:(value.isExpanded),expression:"value.isExpanded"}],class:_vm.$style.table},[_c('thead',[_c('tr',{class:_vm.$style.header},_vm._l((_vm.columns),function(column,number){
          var _obj;
return _c('td',{key:column.name + key + number,style:({ width: column.width }),on:{"click":function($event){return _vm.changeSort(column)}}},[_c('div',{class:_vm.$style.tdInline},[_vm._v(" "+_vm._s(column.name)+" ")]),_c('img',{class:[
                  _vm.$style.tdInline,
                  _vm.$style.arrow,
                  ( _obj = {}, _obj[_vm.$style.asc] = column.sort == 2, _obj[_vm.$style.hidden] = column.sort == 0, _obj ) ],attrs:{"src":require("@/assets/images/icons/table/sort-arrow.svg")}})])}),0)]),_c('tbody',_vm._l((_vm.getSortedEvents(value.records)),function(record,index){return _c('tr',{key:index,class:[_vm.$style.item]},_vm._l((_vm.columns),function(column){return _c('td',{key:record[column.field] + index},[_vm._v(" "+_vm._s(_vm.getDataField(record[column.field], column.field))+" ")])}),0)}),0)])])})],2)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }