<template>
  <scrollable-container>
    <placeholder v-show="state === reportEnum.requestAwaiting" :text="text" @buttonClick="buildReport" />
    <loader v-show="state === reportEnum.tableLoading" />
    <container v-show="state === reportEnum.tableReady" id="printable" :class="[
      { [$style.message]: state === reportEnum.requestAwaiting },
      $style.scrollableContent,
    ]"
    >
      <div v-if="tableData.length === 0" :class="$style.emptyResult">
        Нет данных за указанный период
      </div>

      <div v-for="(user, index) in tableData" :key="user.id" :class="$style.user">
        <div :class="$style.userStickyToggle" @click="toggle(user)">
          <div :class="[
            $style.expandButton,
            { [$style.expanded]: user.isExpanded },
          ]"
          />
          <div :class="$style.name">
            {{ user.name }} ({{ convertSecondsToDays(user.duration) }})
          </div>
        </div>
        <div v-for="[key, value] in Object.entries(user.dates)" v-show="user.isExpanded" :key="key + user.id"
             :class="$style.userData"
        >
          <div :class="$style.toggle" @click="toggleDate(index, key)">
            <div :class="[
              $style.expandButton,
              { [$style.expanded]: value.isExpanded },
            ]"
            />
            <div :class="$style.date">
              {{ key }} ({{ convertSecondsToDays(value.duration) }})
            </div>
          </div>

          <table v-show="value.isExpanded" :class="$style.table">
            <thead>
              <tr :class="$style.header">
                <td v-for="(column, number) in columns" :key="column.name + key + number"
                    :style="{ width: column.width }" @click="changeSort(column)"
                >
                  <div :class="$style.tdInline">
                    {{ column.name }}
                  </div>
                  <img src="@/assets/images/icons/table/sort-arrow.svg" :class="[
                    $style.tdInline,
                    $style.arrow,
                    {
                      [$style.asc]: column.sort == 2,
                      [$style.hidden]: column.sort == 0,
                    },
                  ]"
                  >
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(record, index) in getSortedEvents(value.records)" :key="index" :class="[$style.item]">
                <td v-for="column in columns" :key="record[column.field] + index">
                  {{ getDataField(record[column.field], column.field) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </container>
  </scrollable-container>
</template>

<script>
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';
import Container from '@/components/common/blocks/container.vue';
import Placeholder from '@/components/common/blocks/report-placeholder.vue';
import { getMicrophoneDetails } from '@/api/methods/reports/microphone/index';
import Loader from '@/components/common/blocks/loader.vue';
import { convertSecondsToDays } from '@/helpers/format-date';

export default {
  components: {
    ScrollableContainer,
    Container,
    Placeholder,
    Loader,
  },
  data: () => ({
    name: 'Детализированный отчет по микрофону',
    tableData: [],
    computersArray: [],
    state: 2,
    reportEnum: Object.freeze({
      tableReady: 1,
      requestAwaiting: 2,
      tableLoading: 3,
    }),
    text: 'Для построения отчета нажмите кнопку',
    columns: [
      { name: 'Программа', field: 'exeName', sort: 2 },
      { name: 'Длительность', field: 'duration', sort: 0 },
    ],
  }),
  computed: {
    isWorktimeOnly() {
      return this.$store.getters['filters/isWorktimeOnly'];
    },
    dateRange() {
      return this.$store.getters['filters/formattedDateRange'];
    },
    checkedComputers() {
      return this.$store.getters['pageSpecificData/checkedComputersList'];
    },
    computersTree() {
      const tree = this.$store.getters['pageSpecificData/computersTree'];

      return tree;
    },
  },
  watch: {
    dateRange(oldValue, newValue) {
      if (
        oldValue.dateFrom.toString() !== newValue.dateFrom.toString()
        || oldValue.dateTo.toString() !== newValue.dateTo.toString()
      ) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
    checkedComputers(oldArray, newArray) {
      if (oldArray !== newArray) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
    isWorktimeOnly(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
  },
  methods: {
    convertSecondsToDays(secNum) {
      return convertSecondsToDays(secNum);
    },
    getDataField(data, field) {
      if (field === 'duration') return this.convertSecondsToDays(data);
      return data;
    },
    changeSort(column) {
      const { sort } = column;
      this.columns.forEach((col) => {
        col.sort = 0;
      });
      if (sort === 1) {
        column.sort = 2;
      } else column.sort = 1;
    },
    getSortedEvents(events) {
      return events.slice(0).sort(this.compare);
    },
    compare(a, b) {
      const index = this.columns.findIndex((x) => x.sort > 0);
      const key = this.columns[index].field;

      a = a[key];
      b = b[key];

      if (typeof a === 'string' && typeof b === 'string') {
        a = a.toLowerCase();
        b = b.toLowerCase();
      }

      if (this.columns[index].sort === 2) {
        if (a > b) {
          return -1;
        }

        if (a < b) {
          return 1;
        }
        return 0;
      }

      if (this.columns[index].sort === 1) {
        if (a < b) {
          return -1;
        }

        if (a > b) {
          return 1;
        }

        return 0;
      }
      return 0;
    },
    toggleDate(user, date) {
      this.$set(
        this.tableData[user].dates[date],
        'isExpanded',
        !this.tableData[user].dates[date].isExpanded,
      );
    },
    toggle(item) {
      item.isExpanded = !item.isExpanded;
    },
    getCheckedComputersFromTree(node) {
      if (node.type === 'computer' && node.checked) {
        this.computersArray.push(node);
      }
      if (node.type === 'folder') {
        node.children.forEach((child) => this.getCheckedComputersFromTree(child));
      }
    },
    buildReport() {
      this.state = this.reportEnum.tableLoading;
      this.tableData = [];
      this.computersArray = [];

      if (this.computersTree) {
        this.computersTree.forEach((node) => this.getCheckedComputersFromTree(node));
      }

      let workTimeOnly = 0;
      if (this.isWorktimeOnly) workTimeOnly = 1;

      if (
        this.dateRange.dateFrom
        && this.dateRange.dateTo
        && this.computersArray
      ) {
        this.promise = getMicrophoneDetails(
          this.computersArray,
          this.dateRange.dateFrom,
          this.dateRange.dateTo,
          workTimeOnly,
        ).then(({ data }) => {
          if (data) {
            this.tableData = data.result;

            this.state = this.reportEnum.tableReady;

            const exportArray = [];

            for (let i = 0; i < data.result.length; i++) {
              const { name } = data.result[i];

              Object.entries(data.result[i].dates).forEach(([key, value]) => {
                const date = key;

                for (let y = 0; y < value.records.length; y++) {
                  const resObj = { Пользователь: name, Дата: date };
                  for (let j = 0; j < this.columns.length; j++) {
                    resObj[this.columns[j].name] = this.getDataField(
                      value.records[y][this.columns[j].field],
                      this.columns[j].field,
                    );
                  }
                  exportArray.push(resObj);
                }
              });
            }

            this.$store.commit('exportData/setMicrophoneDetails', { name: this.name, exportArray });
          } else this.state = this.reportEnum.requestAwaiting;
        });
      }
      return this.tableData;
    },
  },
};
</script>

<style lang="scss" module>
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.scrollableContent {
  margin: 0 8px 0 0;
  padding: 0 12px 0 20px;
  box-shadow: $shadow;
  flex: 1;
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyResult {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.userStickyToggle {
  position: sticky;
  z-index: 3;
  top: -1px;
  width: 100%;
  line-height: 14px;
  display: inline-flex;
  cursor: pointer;
  margin-left: 12px;
  align-items: center;
}

.user {
  margin: 5px;
}

.expandButton {
  flex-shrink: 0;
  margin-left: -27px;
  margin-right: 11px;
  width: 14px;
  height: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $branchColor;
  background: $white;
  z-index: 2;

  &::before,
  &::after {
    content: '';
    background-color: $blue;
    width: 8px;
    height: 2px;
    position: absolute;
    transition: 0.25s ease-out;
  }

  &::before {
    transform: rotate(90deg);
  }

  &::after {
    transform: rotate(180deg);
  }

  &.expanded {
    &::before {
      transform: rotate(0);
    }

    &::after {
      transform: rotate(0);
    }
  }
}

.name {
  font-weight: 500;
  display: inline-flex;
  background-color: #f5f5f5;
  width: 100%;
  align-items: center;
  padding: 5px 10px;
  margin-right: 12px;
  font-size: 14px;
}

.userData {
  margin-left: 30px;
  position: relative;
}

.date {
  background-color: $light-gray;
  letter-spacing: -0.24px;
  height: 24px;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;
  color: #4b4b4b;
  margin-top: 10px;
  margin-bottom: 5px;
}

.toggle {
  cursor: pointer;
}

.table {
  width: 100%;
  margin-bottom: 15px;
  border-collapse: collapse;
  font-weight: 400;
  word-break: break-word;
  border: 1px solid $borderColor;
  padding-bottom: 20px;
}

.header {
  background: #dbf1ff;
  height: 31px;
  position: sticky;
  top: 22px;
  z-index: 2;

  td {
    border: 1px solid $borderColor;
    align-items: center;
    text-align: center;
    color: #232326;
    letter-spacing: -0.08px;
    font-size: 13px;
    padding: 4px;
    cursor: pointer;
    vertical-align: middle;
  }
}

.tdInline {
  display: inline-flex;
}

.arrow {
  float: right;
  margin-top: 7px;
  margin-right: 5px;

  &.asc {
    transform: rotate(180deg);
  }

  &.hidden {
    visibility: hidden;
  }
}

.item {
  height: 29px;
  cursor: pointer;

  &:hover {
    background: $light-gray;
  }

  td {
    border: 1px solid $borderColor;
    align-items: center;
    letter-spacing: -0.24px;
    color: $black;
    font-weight: 300;
    font-size: 13px;
    line-height: 14px;
    text-align: center;
    padding: 5px;
    vertical-align: middle;
  }
}
</style>
