<template>
  <div :class="$style.message">
    <div :class="$style.text">
      {{ text }}
    </div>
    <button :class="$style.buildButton" @click="$emit('buttonClick')">
      Построить отчет
    </button>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" module>
.message {
  margin: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.text {
  padding-bottom: 15px;
}

.buildButton {
  align-self: center;

  cursor: pointer;
  display: flex;

  align-items: center;
  justify-content: center;

  width: 195px;
  height: 28px;
  float: right;

  background: $blue;
  border: 2px solid $blue;
  border-radius: 2px;

  font: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: $white;
}
</style>
